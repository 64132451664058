.av-primary {
  color: #0F2946;
}

.av-bg-primary {
  background-color: #0F2946;
}

.ff-mi {
  font-family: 'Rajdhani' !important;
  font-weight: bold !important;
}

.ff-mo {
  font-family: 'Montserrat' !important;
}
.ff-pp {
  font-family: 'Poppins' !important;
}

.ts-10 {
  font-size: 10px !important;
}

.ts-11 {
  font-size: 11px !important;
}

.ts-12 {
  font-size: 12px !important;
}

.ts-13 {
  font-size: 13px !important;
}

.ts-14 {
  font-size: 14px !important;
}

.ts-15 {
  font-size: 15px !important;
}

.lsp-1 {
  letter-spacing: 1px;
}

.bg-btn-g {
  background-image: linear-gradient(to bottom right, #1D69E7, #083278);
}

.home-bg-img {
  background-image: url('../public/assets/Frame_1.webp');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
}

.about-bg-img {
  background-image: url('../public/assets/Frame_25.webp');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
}

.gallery-bg-img {
  background-image: url('../public/assets/Frame_29_4_1.webp');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
}

.product-bg-img {
  background-image: url('../public/assets/Frame_29.webp');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
}

.product-bg-img1 {
  background-image: url('../public/assets/Artboard_3_2_2_1.webp');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
}

.product-bg-img2 {
  background-image: url('../public/assets/Artboard_3_4.webp');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
}

.product-bg-img3 {
  background-image: url('../public/assets/Artboard_3_5.webp');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
}

.expert-bg-img {
  background-image: url('../public/assets/Frame_29_2_1.webp');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
}

.contact-bg-img {
  background-image: url('../public/assets/Frame_29_3_1.webp');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
}

.faq-bg-img {
  background-image: url('../public/assets/Frame_39.webp');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
}

.product-bg-layer {
  background-image: url('../public/assets/Frame_36.webp');
  background-color: #0F2946;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
}

.bg-blur-img {
  background-image: url('../public/assets/element-blur-2_1.webp');
  background-position: center;
  background-repeat: no-repeat;
  background-size: 250px 250px;
  height: 200px;
  border-radius: 50%;
}

/* .blur-section {
  position: absolute;
  height: 250px;
  margin-top: -110px;
  margin-left: -70px;
} */

.circle-section-1 {
  position: absolute;
  height: 150px;
  margin-top: -65px;
  margin-left: -325px;
}

.circle-section-2 {
  position: absolute;
  height: 250px;
  margin-top: -15px;
  margin-left: 115px;
}

/* .blur-section-2 {
  position: absolute;
  height: 250px;
  margin-top: -250px;
  margin-left: -80px;
}

.blur-section-3 {
  position: absolute;
  height: 250px;
  margin-top: -150px;
  margin-left: -80px;
}

.blur-section-4 {
  position: absolute;
  height: 250px;
  margin-top: -150px;
  margin-left: 140px;
}

.blur-section-5 {
  position: absolute;
  height: 250px;
  margin-top: -160px;
  margin-left: -80px;
}

.blur-section-6 {
  position: absolute;
  height: 250px;
  margin-top: -170px;
  margin-left: -100px;
}

.blur-section-7 {
  position: absolute;
  height: 250px;
  margin-top: -170px;
  margin-left: 180px;
} */

.input::-webkit-input-placeholder {
  /* WebKit browsers */
  color: #fff;
}

.input:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #fff;
}

.input::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #fff;
}

.input:-ms-input-placeholder {
  /* Internet Explorer 10+ */
  color: #fff;
}

.error::-webkit-input-placeholder {
  /* WebKit browsers */
  color: red;
}

.error:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: red;
}

.error::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: red;
}

.error:-ms-input-placeholder {
  /* Internet Explorer 10+ */
  color: red;
}
.menu-item {
  margin: 0px 5px !important;
}

.menu-item:hover {
  background-color: #0F2946 !important;
  color: white !important;
  margin: 0px 5px;
  border-radius: 5px;
}

body:has(.menu-item:hover) .main-menu {
  background-color: #0F2946 !important;
  color: white !important;
  margin: 0px 5px;
  border-radius: 5px;
}